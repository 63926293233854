import classNames from "classnames"
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
  createSearchParams,
  useLocation,
} from "react-router-dom"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchCvs } from "../../../../services/utils/fetchCVs"

import {
  JobMatchingStatus,
  setCandidates,
  setJobMatchingStatus,
  setJobQa,
  setJobs,
  setJobStatus,
  setNoRequirementsSet,
  setSelectedCVs,
} from "../../../../redux/slices/jobSlice"
import {
  getJobMatches,
  getMatchedRequirements,
} from "../../../../services/jobs"
import Spinner from "../../Spinner"
import { CustomLink, CustomNavLink } from "../../index"
import { fromNow } from "../../../../util/util"
import { GA_EVENTS } from "../../../../constants/analytics"
import { FormattedMessage, useIntl } from "react-intl"
import { getCvs } from "../../../../services/cv"

const MatchesPageSidebar = () => {
  const { jobs, jobStatus, candidates, selectedCVs, jobMatchingStatus } =
    useSelector((state) => state.jobs)
  const [search, setSearch] = useState("")
  const [searchParams, setSearchParams] = useSearchParams()
  const cvs = useSelector((state) => state.cv.cvs)
  const { formatMessage } = useIntl()

  const tab = searchParams.get("tab") || "job-match"
  const selectedCandidate = searchParams.get("candidate")

  const { jobId } = useParams()

  const actionItems = [
    {
      name: formatMessage({ id: "sidebar.match" }),
      key: "job-match",
    },
    {
      name: formatMessage({ id: "common.profile" }),
      key: "profile",
    },
    {
      name: formatMessage({ id: "common.cv" }),
      key: "cv",
    },
  ]

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // Reset
  useEffect(() => {
    dispatch(setNoRequirementsSet(false))
    dispatch(setSelectedCVs([]))
    dispatch(setJobMatchingStatus(null))
    dispatch(setCandidates({ candidates: null }))
    dispatch(setJobStatus(null))
  }, [jobId, dispatch])

  // Fetch candidates/job info
  useEffect(() => {
    if (
      !jobId ||
      (jobMatchingStatus !== null &&
        jobMatchingStatus !== JobMatchingStatus.MATCHING_IN_PROGRESS)
    ) {
      return
    }

    const fetchCVs = async () => {
      dispatch(setNoRequirementsSet(false))
      dispatch(setSelectedCVs([]))
      try {
        const data = await loadCandidates({ ad_id: jobId })
        dispatch(setJobStatus("COMPLETED"))
        dispatch(setJobQa(data.qa))
        dispatch(setSelectedCVs(data.selected_candidates ?? []))
        dispatch(setJobMatchingStatus(data.matchingStatus))

        if (data.candidates?.length > 0) {
          const candidateKey = data.candidates[0]?.id

          if (selectedCandidate) {
            // Check if this id is in the list of candidates
            const candidate = data.candidates.find(
              (candidate) => candidate.id === selectedCandidate,
            )
            if (!candidate) {
              setSearchParams({ candidate: candidateKey })
            }
          } else {
            setSearchParams({ candidate: candidateKey })
          }
        }

        dispatch(setCandidates({ candidates: data.candidates ?? [] }))
      } catch (err) {
        dispatch(setNoRequirementsSet(true))
      }
    }

    fetchCVs()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId, jobStatus, dispatch])

  // Fetch job matches
  useEffect(() => {
    if (!jobs) {
      // Fetch jobs
      getJobMatches()
        .then((data) => {
          dispatch(setJobs(data))
        })
        .catch((err) => {})
    }
  }, [])

  // Navigate to first job if no job is selected
  useEffect(() => {
    if (jobs?.length > 0 && !jobId) {
      navigate(`/job-match/${jobs[0].id}/matches`)
    }
  }, [jobId, jobs, navigate])

  const job = jobs?.find((job) => job.id === jobId)

  const filteredJobs = useMemo(() => {
    const searchKeyword = search.toLowerCase().trim()
    if (!jobs) return []
    return jobs.filter((job) =>
      job.information.title.toLowerCase().includes(searchKeyword),
    )
  }, [search, jobs])

  // Fetch CVs
  useEffect(() => {
    if (!cvs) {
      fetchCvs(dispatch)
    }
  }, [cvs, dispatch])

  return (
    <div className="matches">
      {!jobs ? (
        <Spinner variant="secondary" />
      ) : jobs.length === 0 ? (
        <p className="matches__info">
          <FormattedMessage id="sidebar.noJobMatches" />
        </p>
      ) : (
        <>
          {job && (
            <>
              <div>
                <p className={"matches__job-ad matches__job-ad--selected"}>
                  {job.information.title}
                  <span>{fromNow(job.updated_at)}</span>
                </p>
                <CustomNavLink
                  className={(props) =>
                    classNames("matches__menu-item", {
                      "matches__menu-item--selected": props.isActive,
                    })
                  }
                  to={`/job-match/${job.id}/candidates`}
                  trackingEvent={GA_EVENTS.JOB_MATCHES.SIDE_BAR_CANDIDATE_CVS}
                >
                  <FormattedMessage id="sidebar.candidateCVs" />{" "}
                  <span>{selectedCVs.length}</span>
                </CustomNavLink>
                <CustomLink
                  className={"matches__menu-item"}
                  to={`/job-match/${job.id}/requirements`}
                  trackingEvent={GA_EVENTS.JOB_MATCHES.SIDE_BAR_REQUIREMENTS}
                >
                  <FormattedMessage id="sidebar.jobRequirements" />{" "}
                  <span>
                    <FormattedMessage id="sidebar.viewOrEdit" />
                  </span>
                </CustomLink>

                {jobStatus !== "COMPLETED" && <Spinner variant="secondary" />}
                {jobStatus === "COMPLETED" && candidates?.length === 0 && (
                  <p className="matches__info">
                    <FormattedMessage id="sidebar.noMatchesFound" />
                  </p>
                )}
                {candidates?.length > 0 && (
                  <div className="matches__candidates">
                    {candidates.map((candidate, i) => {
                      const selected =
                        candidate.id === selectedCandidate &&
                        pathname.includes("/matches")
                      const exists = cvs?.find((cv) => cv.id === candidate.id)
                      return (
                        <div
                          role={"button"}
                          className={classNames("matches__candidate", {
                            "matches__candidate--selected": selected,
                          })}
                          key={`Candidate - ${i}`}
                          onClick={() =>
                            navigate({
                              pathname: `/job-match/${jobId}/matches`,
                              search: createSearchParams({
                                tab: "job-match",
                                candidate: candidate.id,
                              }).toString(),
                            })
                          }
                        >
                          <div className="matches__candidate-info">
                            <span>{`${candidate?.cv?.information?.first_name} ${candidate?.cv?.information?.last_name}`}</span>
                            <span>{Math.round(candidate.score)}</span>
                          </div>
                          {selected && (
                            <div className="matches__candidate-actions">
                              {actionItems
                                .slice(0, exists ? undefined : 1)
                                .map((action) => (
                                  <button
                                    key={action.key}
                                    className={classNames(
                                      "matches__candidate-action-item",
                                      {
                                        "matches__candidate-action-item--selected":
                                          tab === action.key,
                                      },
                                    )}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      setSearchParams({
                                        tab: action.key,
                                        candidate: selectedCandidate,
                                      })
                                    }}
                                  >
                                    {action.name}
                                  </button>
                                ))}
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </>
          )}

          <div className="matches__toolbar">
            <p className="matches__recent">
              <FormattedMessage id="sidebar.recentJobMatches" />
            </p>

            <input
              className="matches__search"
              value={search}
              placeholder="Find"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {filteredJobs.map((job, i) =>
            job.id !== jobId ? (
              <div key={`Job - ${i}`}>
                <Link
                  className="matches__job-ad"
                  to={`/job-match/${job.id}/matches`}
                >
                  {job.information.title}
                  <span>{fromNow(job.updated_at)}</span>
                </Link>
              </div>
            ) : null,
          )}
        </>
      )}
    </div>
  )
}

const loadCandidates = async (data_to_send) => {
  const { data } = await getMatchedRequirements(data_to_send)
  const allCandidates = await getCvs()

  const threshold = data.job_meta?.threshold
  const topK = data.job_meta?.actual_topK

  const candidates =
    data?.candidates
      ?.map((candidate) => {
        try {
          const { score_detail, applicant_id } = candidate

          const cv = allCandidates.find(({ id }) => id === applicant_id)

          const overallScore = Number(score_detail.summary_score)

          return {
            score: overallScore,
            cv,
            success: true,
            id: cv.id,
            qa_justification: candidate.candidate_qa || [],
            score_dict: score_detail.score_dict ?? {},
            score_meta: score_detail.score_meta ?? {},
          }
        } catch (e) {
          return null
        }
      })
      .filter((val) => val) ?? []

  // Remove duplicates. Only single entry of user_id is allowed
  let unique_candidates = candidates
    .filter((candidate) => candidate.cv)
    .sort((a, b) => b.score - a.score)

  if (topK) {
    unique_candidates = unique_candidates.slice(0, topK)
  } else if (threshold) {
    const newCandidates = unique_candidates.filter(
      (candidate) => Math.round(candidate.score) >= Number(threshold),
    )

    if (newCandidates.length < 3) {
      unique_candidates = unique_candidates.slice(0, 3)
    } else {
      unique_candidates = newCandidates
    }
  }

  return {
    candidates: unique_candidates,
    title: data.title,
    status: data.status,
    matchingStatus: data.matchingStatus,
    topK: data.top_k,
    qa: data.job_qa,
    selected_candidates: data.selected_candidates || [],
  }
}

export default MatchesPageSidebar
