import React from "react"
import Tooltip from "../Tooltip"
import { copyToClipboard } from "../../../util/util"
import { FormattedMessage } from "react-intl"


const scoreDetailMeta = [
  {
    propertyName: "hard_skills",
    title: "common.hardSkills",
  },
  {
    propertyName: "soft_skills",
    title: "common.softSkills",
  },
  {
    propertyName: "experience",
    title: "common.experience",
  },
  {
    propertyName: "education",
    title: "common.education",
  },

]

function CandidateScore({ data }) {
  if (!data) {
    return null
  }
  const { cv, score_dict, score_meta } = data

  return (
    <div className="candidate_container">
      <div className="info-block flex">
        <div className="contact-details flex-col">
          {cv.information?.phone
            ?.filter((val) => val)
            ?.map((number, index) => (
              <Tooltip tooltip={"Click to copy"} key={`Phone - ${index}`}>
                <p onClick={() => copyToClipboard(number)}>
                  <span>Phone </span> {number}
                </p>
              </Tooltip>
            ))}

          {cv.information?.email
            ?.filter((val) => val)
            ?.map((email, index) => (
              <Tooltip tooltip={"Click to copy"} key={`Email - ${index}`}>
                <p onClick={() => copyToClipboard(email)}>
                  <span>Email </span> {email}
                </p>
              </Tooltip>
            ))}

          {cv.information?.website && cv.information?.website !== "N/A" && (
            <Tooltip tooltip={"Click to copy"}>
              <p onClick={() => copyToClipboard(cv.website)}>
                <span>Website </span> {cv.website}
              </p>
            </Tooltip>
          )}

          {cv.information?.dateOfBirth && (
            <p>
              <span>Date of Birth </span> {cv.dateOfBirth}
            </p>
          )}

          {cv.information?.address && (
            <p>
              <span>Address </span>
              {cv.information?.address?.street && `${cv.information?.address.street}, `}
              {cv.information?.address?.city && `${cv.information?.address.city}, `}
              {cv.information?.address?.zip && `${cv.information?.address?.zip}, `}
              {cv.information?.address?.country && `${cv.information?.address.country}`}
            </p>
          )}
        </div>
      </div>
      {scoreDetailMeta
        .filter(({ propertyName }) => score_dict[propertyName])
        .map(({ propertyName, title }) => (
          <div className="box flex" key={propertyName}>
            <div className="sectionName flex-col">
              <div
                className="pieOuter"
                style={{
                  background: `conic-gradient(var(--fribl-blue) ${
                    /* score to angle */
                    score_meta[propertyName] * 36
                  }deg, var(--white) 0deg)`,
                }}
              >
                <p>{Math.round(score_meta[propertyName])}</p>
              </div>

              <p className="skillType">
                <b>
                  <FormattedMessage id={title} />
                </b>{" "}
              </p>
            </div>
            <div className="section-card">
              {Object.entries(score_dict[propertyName]).map(
                ([name, details], j) => (
                  <div key={name}>
                    <div className="row">
                      <strong
                        className="score"
                        style={{ opacity: Math.max(details.score / 10, 0.1) }}
                      >
                        {details.score}
                      </strong>
                      <div className="nameEvidenceContainer">
                        {details.score > 0 ? (
                          <strong className="name">{name}</strong>
                        ) : (
                          <p className="name weak">
                            {name} <em>No&nbsp;evidence</em>
                          </p>
                        )}
                        <div className="evidence">
                          <em>Evidence: </em>
                          {details.explanation}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
        ))}
    </div>
  )
}

export default CandidateScore
